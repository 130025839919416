import React from "react";

const SocialCard = (props) => {
  return (
    <a href={props.link} className="social-card">
      <div className="image-container">
        <img src={props.icon} alt={props.iconAlt} className="social-icon" />
      </div>
      <p className=" margin-top-10">{props.text}</p>
    </a>
  );
};

export default SocialCard;
