import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

export default class App extends React.PureComponent {
  render() {
    return (
      <Router>
        <div className="app" id="page">
          <a href="#content" className="skip-to-content-link">
            Skip to Content
          </a>
          <Header />
          <div className="site-content" id="content">
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}
