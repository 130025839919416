import React from "react";

const BookOutCard = (props) => {
  return (
    <div className="outbook-card center">
      <div className="out-image margin-auto">
        <img src={props.cover} alt={props.bookTitle} />
      </div>
      <div className="outbook-card-inner center">
        <div className="bookcard-footer">
          <a
            href={props.bookLink}
            className={`button book-cta lyellow bold ${props.cardCtaClass}`}
          >
            see Book Details
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookOutCard;
