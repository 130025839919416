import React from "react";
import logo from "../../assets/images/logo/logo-120.png";

const Header = () => {
  return (
    <header className="header dark-bg">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="header-contents">
              <a href="/" className="site-brand block inherit">
                <div className="image-container">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="uppercase center white">
                  <h1 className="brand-name">malidad books</h1>
                  <p className="brand-slogan">
                    Books By MALIDAD.COM
                  </p>
                </div>
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
