import React from "react";
import logo from "../../assets/images/logo/logo-120.png";
const Footer = () => {
  return (
    <section className="footer dark-bg section-spacing-40 no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="site-brand">
              <div className="image-container">
                <img src={logo} alt="Logo" className="logo" />
              </div>
              <div className="uppercase center white">
                <h1 className="brand-name">malidad books</h1>
                <p className="brand-slogan">
                  Written to Educate. Written to Inspire.
                </p>
              </div>
            </div>
            <div className="menu-container center section-spacing-20">
              <ul className="footer-menu">
                <li>
                  <a href="#about">About</a>
                </li>
                <li>
                  <a href="#contacts">Contacts</a>
                </li>
                <li>
                  <a href="https://malidad.com">Malidad.com</a>
                </li>
              </ul>
            </div>

            <div className="copy uppercase center smoky-white  section-spacing-10 no-padding-top">
              <p>&copy; 2022, The Malidad Company, All rights reserved</p>
            </div>
            <div className="designer padding-4 smoky-bg">
              <div className="design-inner center dark uppercase bold">
                <p>
                  Website designed by{" "}
                  <a href="https://ntuna.com" className="link">
                    NTUNA.COM
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
