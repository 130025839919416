import React from "react";
import { Helmet } from "react-helmet";
import AboutMalidad from "../components/home/AboutMalidad";
import BooksOut from "../components/home/BooksOut";
import Contacts from "../components/home/Contacts";
import Social from "../components/home/Social";
const Home = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.books.malidad.com/" />
      </Helmet>
      <BooksOut />
      <Contacts />
      <Social />
    </>
  );
};

export default Home;
